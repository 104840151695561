import React from "react"
import { useIntl, Link } from "gatsby-plugin-react-intl"
import { BgImage } from "gbimage-bridge"
import { RightOutlined } from "@ant-design/icons"
import styled from "@emotion/styled"

const CoverContainer = styled.div`
  .bgImage {
    position: relative;
    width: 100%;
    min-height: 380px;
    padding: 60px 0 60px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(21, 21, 21, 0.2);
      z-index: 1;
    }
    .coverText {
      font-family: "Kanit", sans-serif;
      font-weight: 400;
      font-size: 32px;
      color: #fff;
      text-shadow: 0px 4px 46px rgba(0, 0, 0, 0.25);
      padding: 0 15px;
      margin-bottom: 10px;
      text-align: center;
      z-index: 2;
    }
  }

  @media only screen and (min-width: 576px) {
    .bgImage {
      min-height: 414px;
      .coverText {
        font-size: 34px;
      }
    }
  }
  @media only screen and (min-width: 768px) {
    .bgImage {
      min-height: 480px;
      .coverText {
        font-size: 36px;
      }
    }
  }
  @media only screen and (min-width: 992px) {
    .bgImage {
      min-height: 520px;
      .coverText {
        font-size: 52px;
      }
    }
  }
`
const Breadcrumb = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  z-index: 3;
  .item {
    font-family: "Kanit", sans-serif;
    font-weight: 400;
    font-size: 22px;
    color: #fff;
    text-shadow: 0px 4px 46px rgba(0, 0, 0, 0.25);
    margin: 0 5px;
    a {
      color: #fff;
      text-decoration: none;
    }
    .text {
      font-weight: 300;
      font-style: italic;
    }
  }
  .separator {
    line-height: 0;
    .anticon {
      font-size: 14px;
      color: #fff;
      margin-left: 8px;
      margin-right: 8px;
    }
  }
`

const HeaderCover = ({ bgImage, coverText, secondLink, secondText }) => {
  const intl = useIntl()

  return (
    <CoverContainer>
      <BgImage image={bgImage} className="bgImage">
        <div className="overlay"></div>
        <h1 className="coverText">{coverText}</h1>
        <Breadcrumb>
          <div className="item">
            <Link to="/">{intl.formatMessage({ id: "navMenu1" })}</Link>
          </div>
          <span className="separator">
            <RightOutlined />
          </span>
          {secondLink && secondText ? (
            <>
              <div className="item">
                <Link to={secondLink}>{secondText}</Link>
              </div>
              <span className="separator">
                <RightOutlined />
              </span>
            </>
          ) : null}
          <div className="item">
            <span className="text">{coverText}</span>
          </div>
        </Breadcrumb>
      </BgImage>
    </CoverContainer>
  )
}

export default HeaderCover
